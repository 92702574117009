import { Box, makeStyles, Typography } from "@material-ui/core";
import { residualData } from "../utils";

export default function Benefits() {
  const classes = useStyles();
  return (
    <Box className={classes.residual}>
      <Typography variant="h4">{residualData.title}</Typography>
      <Typography variant="subtitle1" style={{whiteSpace:"pre-wrap"}}>{residualData.description}</Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  residual: {
    backgroundColor: '#b1dbf7',
    padding: theme.spacing(3),
    borderRadius: "14px",
    marginTop: theme.spacing(10),
    [theme.breakpoints.down(1056)]: {
      marginTop: theme.spacing(4),
    },
    // [theme.breakpoints.down('sm')]: {
    //   textAlign: 'center',
    // },
  },
}));