import Button from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';


function IndicatorButton({account, connectMetaMask}) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Button
      style={{marginLeft: !match?'40px':'0px'}}
      id='connectButton'
      variant='outlined'
      color='primary'
      startIcon={
        <FiberManualRecordIcon
          fontSize='small'
          style={{ color: account ? green[500] : red[500] }}
        />
      }
      onClick={connectMetaMask}
    >
      {account
        ? account.slice(0, 7) + '...' + account.slice(account.length - 5)
        : 'Connect Wallet'}
    </Button>
  );
}

export default IndicatorButton;
