import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import IndicatorButton from "./indicatorbutton";
import { headerData } from "../utils";
import logo from "../assets/logo.png";
import { ReactComponent as Discord } from "../assets/discord.svg";
import { ReactComponent as Opensea } from "../assets/opensea.svg";
import { ReactComponent as Insta } from "../assets/insta.svg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";

const Nav = ({
  scrollFAQ,
  scrollToAbout,
  scrollToBenefits,
  scrollToRoadmap,
  scrollToTeam,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.nav}>
      <Link
        underline="always"
        component="button"
        variant="button"
        onClick={scrollToAbout}
      >
        About
      </Link>
      {/* <Link
        underline="always"
        component="button"
        variant="button"
        onClick={scrollToBenefits}
      >
        Benefits
      </Link> */}
      <Link
        underline="always"
        component="button"
        variant="button"
        onClick={scrollToRoadmap}
      >
        Roadmap
      </Link>
      <Link
        underline="always"
        component="button"
        variant="button"
        onClick={scrollFAQ}
      >
        FAQ
      </Link>
      <Link
        underline="always"
        component="button"
        variant="button"
        onClick={scrollToTeam}
      >
        Team
      </Link>
    </Box>
  );
};

const Social = (props) => {
  return (
    <Box>
      <IconButton size="small" href={headerData.socialLinks.discord}>
        <Discord height="35px" width="35px" />
      </IconButton>
      <IconButton size="small" href={headerData.socialLinks.opensea}>
        <Opensea height="35px" width="35px" />
      </IconButton>
      {/* <IconButton size="small" href={headerData.socialLinks.instagram}>
        <Insta height="35px" width="35px" />
      </IconButton> */}
      <IconButton size="small" href={headerData.socialLinks.twitter}>
        <Twitter height="35px" width="35px" />
      </IconButton>
    </Box>
  );
};

const Header = ({
  account,
  connectMetaMask,
  scrollFAQ,
  scrollToAbout,
  scrollToBenefits,
  scrollToRoadmap,
  scrollToTeam,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const _xs = useMediaQuery(theme.breakpoints.down(473));

  return !match ? (
    <Box className={classes.root}>
      <Box className={classes.logoContainer}>
        <img src={logo} className={classes.logo} alt="logo" />
      </Box>
      <Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Nav
            scrollToAbout={scrollToAbout}
            scrollToBenefits={scrollToBenefits}
            scrollToRoadmap={scrollToRoadmap}
            scrollToTeam={scrollToTeam}
            scrollFAQ={scrollFAQ}
          />
          <Social />
          <IndicatorButton {...{ account, connectMetaMask }} />
        </Box>
        <Typography
          variant="subtitle2"
          style={{
            letterSpacing: "1px",
            wordSpacing: "5px",
            marginTop: "10px",
          }}
        >
          {headerData.slogan}
        </Typography>
      </Box>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Box
        style={{
          display: "flex",
          justifyContent: _xs ? "space-between" : "center",
          width: _xs ? "100%" : "auto",
        }}
      >
        <Box className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
        </Box>
        <Box
          pt={1}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <IndicatorButton {...{ account, connectMetaMask }} />
          {!_xs && <Social />}
        </Box>
      </Box>
      {_xs && <Social />}
      <Typography variant="subtitle2">{headerData.slogan}</Typography>
      <Nav
        scrollToAbout={scrollToAbout}
        scrollToRoadmap={scrollToRoadmap}
        scrollToTeam={scrollToTeam}
        scrollFAQ={scrollFAQ}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      textAlign: "center",
      flexDirection: "column",
      // minHeight: '265px',
      paddingTop: "10px",
      "& > *": {
        marginBottom: "10px",
      },
    },
  },
  logo: {
    maxHeight: "8rem",
    [theme.breakpoints.down(473)]: {
      maxHeight: "4rem",
    },
  },
  logoContainer: {
    flexGrow: 1,
    [theme.breakpoints.down(473)]: {
      flexGrow: 0,
    },
  },
  nav: {
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
}));

export default Header;
