import { useState } from "react";
import Slider from "@material-ui/core/Slider";
import { Grid, Box, Button, makeStyles, Typography } from "@material-ui/core";
import { aboutData } from "../utils";
import gif from "../assets/gif.gif";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const About = ({ totalSupply, price, mint }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      spacing={5}
      className={classes.root}
      direction={match ? "column-reverse" : "row"}
    >
      <Grid className={classes.item} item lg={6} md={6} sm={12}>
        <Typography variant="h3">{aboutData.title}</Typography>
        <Typography>{aboutData.description}</Typography>
        <Box component="div" mt={6} className={classes.text}>
          <Box component="div" my={2}>
            <Typography variant="h6" align="center">
              Total Decadent Dogs: {totalSupply}/ 6969
            </Typography>
          </Box>
          <Box component="div" mx={1}>
            <Slider
              style={{ color: "gray" }}
              defaultValue={0}
              max={5}
              value={value}
              onChange={handleChange}
            />
          </Box>
          <Typography
            component="div"
            variant="overline"
            mb={2}
            className={classes.gray}
            align="center"
          >
            Mint {value} Decadent Dogs ({(value * price).toFixed(3)} ether)
          </Typography>
          <Box style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                mint(value);
              }}
            >
              Mint Decadent Dogs
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid
        className={classes.item + " " + classes.right}
        item
        lg={6}
        md={6}
        sm={12}
      >
        <span className={classes.relative}>
          <img src={gif} alt="banner" className={classes.media} />
        </span>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.down(1056)]: {
      marginTop: theme.spacing(3),
    },
  },
  residual: {
    backgroundColor: "#b1dbf7",
    padding: theme.spacing(3),
    borderRadius: "14px",
    marginTop: theme.spacing(10),
    [theme.breakpoints.down(1056)]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  media: {
    maxHeight: "430px",
    borderRadius: "25px",
    [theme.breakpoints.down(500)]: {
      maxHeight: "300px",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "10px",
    },
  },
  relative: {
    position: "relative",
    display: "inline-block",
  },
  border: {
    top: -25,
    left: -10,
    position: "absolute",
    height: "108%",
    zIndex: 1,
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  right: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  gray: {
    color: theme.palette.grey[800],
  },
  text: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    border: "3px solid #111F2E",
    borderRadius: "12px",
    padding: "1px 0px 6px 12px",
  },
}));

export default About;
