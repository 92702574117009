import { useState, useEffect, useRef } from "react";
import Web3 from "web3";
import Container from "@material-ui/core/Container";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import theme from "./theme";
import Header from "./components/header";
import Cover from "./components/cover";
import About from "./components/about";
import Benefits from "./components/benefits";
import RoadMap from "./components/roadmap";
import FAQ from "./components/faq";
import Team from "./components/team";
import Footer from "./components/footer";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { contractAbi, contractAddress } from "./config";
import proofs from "./proofs.json";

function App() {
  const classes = useStyles();
  const [chainId, setChainId] = useState(null);
  const [method, setMethod] = useState(null);
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [price, setPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);

  useEffect(() => {
    account && method && fireToast();
  }, [method]);

  useEffect(async () => {
    loadWeb3();
  }, [account]);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      try {
        loadBlockchainData();
        getCurrentAddressConnected();
        addAccountsAndChainListener();
      } catch (error) {
        console.error(error);
      }
    } else {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }
  const loadBlockchainData = async () => {
    const contract = new window.web3.eth.Contract(contractAbi, contractAddress);
    setContract(contract);
    const chainId = await window.web3.eth.getChainId();
    setChainId(chainId);
    {
      chainId === 1 ? setMethod("success") : setMethod("error");
    }
    // method && fireToast()

    if (chainId === 1) {
      const totalSupply = await contract.methods.totalSupply().call();
      setTotalSupply(totalSupply);

      const price = await contract.methods.price().call();
      setPrice(price);
      const displayPrice = window.web3.utils.fromWei(price, "ether");
      setDisplayPrice(displayPrice);

      //event will be fired by the smart contract when a new Decadent Dog is minted
      contract.events
        .DecadentDogsClubMinted()
        .on("data", async function (result) {
          setTotalSupply(result.returnValues[0]);
        })
        .on("error", console.error);
    }
  };

  const getCurrentAddressConnected = async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        setAccount(accounts[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const addAccountsAndChainListener = async () => {
    //this event will be emitted when the currently connected chain changes.
    window.ethereum.on("chainChanged", (_chainId) => {
      window.location.reload();
    });

    // this event will be emitted whenever the user's exposed account address changes.
    window.ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        document.getElementById("connectButton").disabled = true;
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        document.getElementById("connectButton").disabled = false;
        setAccount(accounts[0]);
        method && fireToast();
      } catch (error) {
        if (error.code === 4001) {
          swal("Request to access account denied!", "", "error");
        }
        document.getElementById("connectButton").disabled = false;
      }
    }
  };

  async function mint(mintCount) {
    if (contract) {
      if (chainId === 1) {
        if (mintCount === 0) {
          swal("Minimum mint amount is 1 Decadent Dog", "", "info");
        } else {
          try {
            if (account) {
              const finalPrice = Number(price) * mintCount;
              const proof = proofs[account.toLowerCase()];
              if (!proof) return swal("You are not eligible", "", "error");
              await contract.methods
                .presaleMint(proof, mintCount)
                .send({ from: account, value: finalPrice });
            }
          } catch (error) {
            if (error.code === 4001) {
              swal("Transaction Rejected!", "", "error");
            } else {
              console.log(error);
              swal("Transaction Failed!", "", "error");
            }
          }
        }
      } else {
        swal("Please switch to mainnet to mint Decadent Dog", "", "error");
      }
    } else {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }
  const fireToast = () => {
    toast[method](
      `You are ${method === "error" ? "not" : ""} connected to mainnet`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      }
    );
  };

  const aboutRef = useRef(null);
  const benefitsRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);
  const faqRef = useRef(null);

  const scrollToAbout = () =>
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToBenefits = () =>
    benefitsRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToRoadmap = () =>
    roadmapRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToTeam = () =>
    teamRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToFaq = () =>
    faqRef.current.scrollIntoView({ behavior: "smooth" });

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <CssBaseline />
      <>
        <Container maxWidth="lg" className={classes.root}>
          <Header
            scrollToAbout={scrollToAbout}
            scrollToBenefits={scrollToBenefits}
            scrollToRoadmap={scrollToRoadmap}
            scrollToTeam={scrollToTeam}
            scrollFAQ={scrollToFaq}
            account={account}
            connectMetaMask={connectMetaMask}
          />
          {/* <Cover /> */}
          <div ref={aboutRef} id="about">
            <About totalSupply={totalSupply} price={displayPrice} mint={mint} />
          </div>
          <div ref={benefitsRef} id="benefits">
            <Benefits />
          </div>
          <div ref={roadmapRef} id="roadmap">
            <RoadMap />
          </div>
          <div ref={faqRef} id="faq">
            <FAQ />
          </div>
          <div ref={teamRef} id="team">
            <Team />
          </div>
        </Container>
        <Footer />
      </>
    </ThemeProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    // background: 'linear-gradient(0deg , #212a5a , #03a9f4)',
    // overflow: 'hidden',
  },
}));

export default App;
