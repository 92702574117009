import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import { useMediaQuery } from "@material-ui/core";
import Card from './card';
import { teamData } from '../utils';

export default function Team(params) {
  // const _md = useMediaQuery("(min-width:600px) and (max-width:960px)");
  const classes = useStyles();

  return (
    <Box component='div'>
      <span id="team" ></span>
      <Box component='div' className={classes.root}>
        <Typography variant='h3' color='textSecondary'>{teamData.title}</Typography>
      </Box>
      <Grid container justifyContent='center' spacing={5}>
        {teamData.members.map((item, index) => {
          return (
            <Grid key={item.name} item>
              <Card
                name={item.name}
                img={item.image}
                designation={item.designation}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    padding: '15px',
  },
}));
