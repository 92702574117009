import ddc1 from "./assets/team1.jpg";
import ddc2 from "./assets/team2.jpg";
import ddc3 from "./assets/team3.jpg";
import ddc4 from "./assets/team4.jpg";

export const footerData = {
  description: "Smart contract",
  link: "https://etherscan.io/address/0xf70195c0fcA525DdE62a9d9c3a4F0Cfd59384f65",
};

export const aboutData = {
  title: "What is Decadent Dogs Club?",
  description:
    "Decadent Dogs Club is 6,969 unique digital masterpieces chosen by Señor Benny. Our mission at Decadent Dogs Club is to have fun, create a belligerent community, and give back where we can. While we may look cute and cuddly, don’t be fooled, inside we are downright deplorable.",
};

export const residualData = {
  title: "Utility",
  description: `
For each Decadent Dog NFT purchased we will pay for a tree to be planted by donating proceeds to the One Tree Planted organization. By the time we are finished, we will have planted a small forest.

We are passionate about helping our fellow rescue mutts so we will also be donating to various dog shelters voted on monthly by the community in our discord. A percentage of each sale will go towards this fund.

Within the first month, we will be creating a turnkey online store where you can upload your NFTs and order customized clothing such as t-shirts, hoodies, hats, etc. Hell, we might even let you order coffee mugs and whatever other crazy stuff the community comes up with. You can even order some of the t-shirts and hoodies in the DDC NFTs.

We’ve built several online stores before and sold millions of dollars in products. We will even allow you to upload NFTs from other projects since most of them can’t seem to figure out this is something the NFT community wants.

Maximum membership 6,969.

20-50 will be held by the team for giveaways, promotional events, and team members.
`,
};

export const teamData = {
  title: "OUR TEAM",
  members: [
    {
      name: "Benny",
      designation: "Art & Design",
      image: ddc1,
    },
    {
      name: "Sasha",
      designation: "Marketing",
      image: ddc2,
    },
    {
      name: "Jean",
      designation: "Project Management",
      image: ddc3,
    },
    {
      name: "Andy",
      designation: "Marketing",
      image: ddc4,
    },
  ],
};

export const roadmapData = [
  {
    heading: "25%",
    description:
      "Some DDC NFTs will be airdropped to our early adopters and fanbase. ",
  },
  {
    heading: "50%",
    description:
      "We will airdrop rare, limited DDC NFTs to randomly selected holders. The more you hold, the greater your chances of winning.",
  },
  {
    heading: "75%",
    description:
      "We will establish a liquidity pool to stabilize the price for DDC NFTs. Our plan is to launch this initiative about 3-4 days after launch to buy up the floor.",
  },
  {
    heading: "100%",
    description1:
      "We will begin working on a free mint for holders. You will just have to pay for gas. We will donate to One Tree Planted and vote on which dog rescue group to donate to via our discord. Our online store will be created where you can upload your NFTs and create custom clothing.",
  },
];

export const faqData = [
  {
    question: "How can I buy a Decadent Dog?",
    answer:
      "You can purchase a dog by clicking on the mint button after you connect your Metamask wallet upon launch..",
  },
  {
    question: "When is the launch and how much?",
    answer:
      "TBD. Follow our Discord and other social channels for the exact date and time.",
  },
  {
    question: "Can I mint on mobile?",
    answer:
      "Yes, you will be able to mint through Metamask on your mobile device. You can also mint on your computer.",
  },
  {
    question: "How many Decadent Dogs can I mint?",
    answer:
      "You can mint 5 per transaction. We did this to keep it fair by giving everyone a chance to mint and join our club. Plus, it lowers your gas fee spread out across 5 NFTs. You can return and mint more than one transaction as many times as you’d like.",
  },
  {
    question: "How many traits are in the club?",
    answer:
      "There are 218 traits in our club with 13 different categories (Fur Color, Fur Pattern, Eyes, Collars, Pendants, Bandanas, Mouth, Eyewear, Hairstyle/Hats, Earrings, Clothing, Background, and Extras. Traits are ranked from common to super rare. ",
  },
  {
    question: "Do you have a rarity system?",
    answer:
      "Yes, rarity is very important to us. You will be able to see the breakdown of our traits on Opensea.",
  },
  {
    question: "What blockchain is this on?",
    answer: "Decadent Dogs Club are ERC-721 tokens on the Ethereum blockchain.",
  },
  {
    question: "Will there be a secondary market?",
    answer:
      "Yes, we will be listing on Opensea post mint, so you are able to trade immediately, although we discourage it as there will be rewards for those that hold.",
  },
  {
    question: "Will you have a community fund?",
    answer:
      "Yes, we will be putting 10% of our sales and secondary market royalties into our community fund. We may need to adjust this number over time. A portion of this will be used to donate to one tree planted and dog rescues. We will also use this money to build and grow our club over time.",
  },
  {
    question: "Are there secondary sales royalties?",
    answer:
      "Yes, royalties are set at 7.5%. We will be contributing 10% of this back into the community fund to grow the club as well as donating to one tree planted and various dog rescues voted on by the community.",
  },

  {
    question: "Will I own the dog after purchasing?",
    answer: "Yes, you will have full intellectual property rights.",
  },
];

export const headerData = {
  socialLinks: {
    twitter: "https://twitter.com/DecadentDogsNFT",
    discord: "https://discord.com/invite/wnajRwFNyE",
    opensea: "https://opensea.io/collection/decadent-dogs-club",
  },
};
